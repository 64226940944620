class Front extends Base {

    Init($) {
        function startCountdown(element, seconds) {
            function updateTimer() {
                let days = Math.floor(seconds / 86400);
                let hours = Math.floor((seconds % 86400) / 3600);
                let minutes = Math.floor((seconds % 3600) / 60);
                let secs = seconds % 60;

                element.innerHTML = `<p>До конца акции осталось:</p>
                    <div class="countdown-wrapper">
                        <div class="countdown-wrapper__item">
                            <span>${days}</span>
                            <p>дней</p>
                        </div>
                        
                        <span>:</span>
            
                        <div class="countdown-wrapper__item">
                            <span>${hours.toString().padStart(2, '0')}</span>
                            <p>часов</p>
                        </div>
                        
                        <span>:</span>
            
                        <div class="countdown-wrapper__item">
                            <span>${minutes.toString().padStart(2, '0')}</span>
                            <p>минут</p>
                        </div>
                        
                        <span>:</span>
                        
                        <div class="countdown-wrapper__item">
                            <span>${secs.toString().padStart(2, '0')}</span>
                            <p>секунд</p>
                        </div>
                    </div>`;

                if (seconds > 0) {
                    seconds--;
                    setTimeout(updateTimer, 1000);
                } else {
                    element.innerHTML = 'Акция завершена';
                }
            }
            updateTimer();
        }

        let countdownElement = document.querySelector('.countdown');
        if (countdownElement) {
            let remainingTime = parseInt(countdownElement.getAttribute('data-time'), 10);
            startCountdown(countdownElement, remainingTime);
        }
    }

}